@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', Inter,
    monospace;
}

.ai-gradient {
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 120%;

    text-align: center;
    letter-spacing: 0.01em;
  
    background: linear-gradient(180deg, #7f3f98 25.23%, #fc833f 95.13%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; 
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(192, 192, 192);
}

.scroller {
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-color: rgb(192, 192, 192);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-corner {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.custom-menu{
  display: none;
}

.custom-menu.active {
  display: flex;
}